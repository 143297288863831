.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full height of the viewport */
  position: absolute; /* To overlay over content */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}